<template>
  <div class="box-layout">
    <HeaderFiveEpitonet>
      <img slot="logo" src="../../assets/img/logo/global-logo.jpg" />
    </HeaderFiveEpitonet>

    <!-- Start Portfolio Details Area -->
    <div class="rf-portfolio-details section-ptb-xl">
      <v-container class="container">
        <v-row>
          <v-col sm="6" cols="12" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../../assets/img/567x600px/567x600px-Nyilaszaro.jpg"
                  alt="portfolio"
                />
              </div>
            </div>
          </v-col>
          <v-col sm="6" cols="12">
            <div class="rp-port-details-inner sticky-top">
              <div class="content">
                <h3>Nyílászárok gyártása, cseréje</h3>
                <p>
                  Gyártunk és beépítünk üvegtetőket, elő-tetőket, ezen túl igény szerint foglalkozunk műanyag nyílászárókkal, belső ajtókkal, edzett üveg szerkezetekkel, mobilfalakkal, automata ajtókkal, ipari és garázskapukkal, árnyékolókkal, szúnyoghálókkal. 

Számunkra nagyon fontos, hogy partnereink részére minőségileg a legjobb és ezen túl komplex szolgáltatást nyújtsuk. 
                </p>
                
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderFiveEpitonet from "../../components/header/HeaderFourEpitonet";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      HeaderFiveEpitonet,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .portfolio-details-page .-rn-footer {
    background: #f9f9f9;
  }
</style>
